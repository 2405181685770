<template>
  <section class="defense-legal">
    <hero-global title="Defensa legal" img="/img/legal-defense/legal-defense.svg" first-width="70" last-width="30" />

    <section class="menu-anclas container">
      <router-link to="#que-hacemos" @click="scrollSection('que-hacemos')" class="btn btn-tertiary">Qué
        hacemos</router-link>
      <router-link to="#autodefensa" @click="scrollSection('autodefensa')"
        class="btn btn-tertiary">Autodefensa</router-link>
      <router-link to="#documentacion" @click="scrollSection('documentacion')"
        class="btn btn-tertiary">Documentación</router-link>
      <router-link to="#discriminacion" @click="scrollSection('discriminacion')" class="btn btn-tertiary">Discriminación
        por discapacidad</router-link>
    </section>
    <section id="que-hacemos" class="container">
      <div class="column-center">
        <h2 class="title-grand title__double-center" data-text="Qué hacemos">Qué hacemos</h2>
      </div>


      <section class="container-grid">
        <div class="grid-big" v-if="!this.store.easyRead.active">
          <h4 class="subtitle">El CERMI presta orientación jurídica a las personas
            con discapacidad y/o sus familias, y a sus entidades, que lo soliciten, en las siguientes condiciones y de
            acuerdo con el procedimiento que se señala a continuación:</h4>
          <br />
          <br />
          <ol class="first-list">
            <li>
              <p>El CERMI ofrece una primera orientación
                jurídica a las personas con discapacidad y a sus organizaciones en supuestos de discriminaciones y
                tratos desiguales de gravedad, sufridos por razón exclusiva de discapacidad.</p>
            </li>
            <li>
              <p>El CERMI, por insuficiencia de medios, solo
                atiende los casos derivados de controversias o disputas de carácter jurídico en los que la discapacidad
                sea un elemento nuclear y determinante. Quedan, pues, descartados los supuestos en los que la presencia
                de la discapacidad es un factor más de problema, pero no supone la razón de ser de la discriminación o
                el trato desigual.</p>
            </li>
            <li>
              <p>
                La orientación jurídica del CERMI se limita a un primer
                consejo y recomendaciones de actuación a la persona o entidad que hayan solicitado el apoyo, una vez
                examinados los supuestos de hecho denunciados o puestos en conocimiento de la Entidad. El CERMI no asume
                en ningún caso la asistencia jurídica directa y continua (servicios de abogado/a y procurador/a,
                redacción y presentación de escritos, etc.).
              </p>
            </li>
          </ol>

        </div>
        <template v-if="this.store.easyRead.active">
          <div class="grid-big">
            <p class="subtitle">El CERMI presta orientación jurídica a las personas con discapacidad y/o sus familias, y
              a sus entidades, que lo soliciten, en las siguientes condiciones y de acuerdo con el procedimiento que se
              señala a continuación:</p>
            <p><strong>La autodefensa es defenderse a uno mismo.</strong></p>
            <p>El CERMI ayuda y aconseja a las personas con discapacidad y sus familias en el caso de que tengan un
              problema de discriminación.</p>
            <p>Hay varios <strong>requisitos</strong> para recibir esta ayuda:</p>
            <p>El CERMI explica a las personas con discapacidad y a sus asociacioneslo que pueden hacer si han sufrido
              discriminación por su discapacidad.</p>
            <p>El CERMI sólo puede atender conflictos o juicios si a una persona la discriminan por su discapacidad.</p>
            <p>El CERMI no puede ayudar en caso de discriminación por otro motivo.</p>
            <p>El CERMI da consejos y recomendaciones a las personas con discapacidad que pidan ayuda.</p>
            <p>El CERMI no ofrece abogados.</p>
            <p>Tampoco prepara documentos para juicios.</p>
            <p>El CERMI da información a las personas con discapacidad de otras ayudas.</p>
            <p>Puedes pedir información legal en asociaciones o instituciones del Gobierno.</p>
            <p>También hay abogados gratuitos.</p>
            <p>Puedes denunciar en el Consejo Nacional de la Discapacidad.</p>
            <p>En el CERMI te explican las ayudas que puedes pedir.</p>
            <p>El CERMI responde a todas las preguntas cuanto antes.</p>
            <p>El CERMI no puede responder en el momento.</p>
            <p>Si sufres discriminación por tu discapacidad, tienes que rellenar en este enlace: <a href="https://defensalegalnet.es" target="_blank">https://defensalegalnet.es</a>.</p>
            <p>En el fax debes poner tu dirección de correo electrónico para recibir la respuesta.</p>
            <p>El CERMI responde a las preguntas por correo electrónico.</p>
            <p>El CERMI no responde a preguntas por teléfono ni en las oficinas.</p>
            <p>El CERMI te llamará por teléfono si necesita más información sobre tu problema.</p>
            <p>Mira en la sección “Preguntas y respuestas” de la página web del CERMI porque tududa puede tener una
              respuesta en esta sección.</p>
            <p>Si pides ayuda al CERMI, debes contar la verdad.</p>
            <p>También debes dar información y documentos que cumplan con la ley.</p>
            <p>El CERMI guardará en secreto tus datos, como tu nombre o tu teléfono.</p>
            <p>También guardará en secreto todos los mensajes y documentos de tu consulta.</p>
          </div>

        </template>
        <div class="grid-small">
          <ribbon text="Defensa legal" />
          <br />
          <iframe width="540" height="320" src="https://www.youtube.com/embed/uf2EE1WEmlA" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </section>
      <section v-if="!this.store.easyRead.active" class="container-grid reverse">
        <div class="grid-small">
          <figure class="float-left align-items-center">
            <img class="w-100" alt="Imagen CERMI" src="/img/legal-defense/legal-defense_1.png" />
          </figure>
        </div>
        <div class="grid-big">
          <ol class="second-list">
            <li>
              <p>
                Tanto los supuestos que el CERMI decida
                atender, ofreciendo una primera orientación, como los que queden descartados por no encajar en los
                criterios señalados anteriormente, se derivarán, siempre con el consentimiento previo de la persona que
                consulta o denuncia y de acuerdo con la normativa de protección de datos (ver punto 9º inferior con más
                detalles sobre esta cuestión), ofreciendo la información disponible, hacia recursos públicos y privados
                existentes que puedan prestar una ayuda más intensa (asesorías jurídicas de organismos públicos y de
                entidades de la discapacidad, servicios de orientación de los Colegios de Abogados, turnos de oficio,
                órganos jurisdiccionales y oficinas del Ministerio Fiscal, órganos de supervisión y fiscalización como
                el Consejo Nacional de la Discapacidad, etc.).
              </p>
            </li>
            <li>
              <p>El CERMI contestará a las consultas recibidas y
                admitidas en el menor tiempo posible, sin poder obligarse a responder de forma instantánea o sometida a
                plazos ajenos al propio CERMI. Por permanecer la Entidad sin actividad en el mes de agosto de cada año,
                debido a vacaciones de su personal, las consultas recibidas durante este periodo temporal se tramitarán
                y comenzarán a contestarse, de ser admitidas, en el mes inmediato posterior (septiembre).</p>
            </li>
          </ol>
        </div>
      </section>
      <section v-if="!this.store.easyRead.active" class="container-grid ">
        <div class="grid-big">
          <ol class="third-list">
            <li>
              <p>La solicitudes de orientación de las personas
                que se dirijan al CERMI se canalizarán exclusivamente a través de este enlace: <a href="https://defensalegalnet.es" target="_blank" rel="noopener noreferrer">https://defensalegalnet.es</a>. No se atenderán consultas
                por vía telefónica, ni tampoco presencialmente.</p>
            </li>
            <li>
              <p>Las solicitudes atendidas se contestarán
                exclusivamente por vía electrónica, enviando correo de este tipo a la cuenta proporcionada por la
                persona solicitante. No se mantendrá consultas telefónicas sobre el caso, salvo que sea preciso recabar
                más información o resulte estrictamente necesario para ofrecer la orientación jurídica, y siempre a
                instancias del CERMI.</p>
            </li>
            <li>
              <p>
                Las personas que soliciten este servicio se hacen
                responsables de la veracidad, adecuación con la realidad y legalidad de los hechos, documentos,
                información o materiales comunicados o puestos a disposición del CERMI.
              </p>
            </li>
            <li>
              <p>El CERMI aplicará estrictamente la legislación sobre
                protección de datos (<router-link to="/politica-privacidad"
                  target="_blank">https://cermi.es/politica-privacidad</router-link>) y el deber de confidencialidad
                de toda la información que pudiera recibir por esta vía.</p>
            </li>
          </ol>
        </div>
        <div class="grid-small">
          <figure class="float-right">
            <img class="w-100" alt="Imagen CERMI" src="/img/legal-defense/legal-defense_2.png" />
          </figure>
        </div>
      </section>

    </section>
    <carrousel2 class="container" />

    <section id="autodefensa" class="background-blue-3">
      <div class="container">
        <div class="column-center">
          <h2 class="title-grand title__double-center" data-text="Autodefensa">Autodefensa</h2>
        </div>
        <h3 class="subtitle text-center">SI HA SIDO VÍCTIMA DE DISCRIMINACIÓN, DENUNCIE: Modelos de denuncia</h3>
        <div class="grid-full m-top-2-rem">
          <div class="grid right">
            <!-- <ribbon class="m-bottom-2-rem" text="Autodefensa" /> -->
            <iframe width="540" height="320" src="https://www.youtube.com/embed/D2qrDKU0FQs"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
          <article class="font-family-roboto text-primary font-size-16 text-bold m-top-2-rem">La información que se
            incluye en esta Guía tiene como objetivo ayudarte para que te conviertas en un/a
            autodefensor/a de tus derechos, involucrándote activamente para cambiar de forma eficaz las situaciones que
            te
            ocasionan un perjuicio solamente porque eres una persona con discapacidad.<br /><br />

            Seguro que más de una vez te has encontrado ante situaciones en las que te has sentido discriminado/a porque
            existían obstáculos que te impedían ejercer tus derechos. Es necesario cambiar algunas cosas de la sociedad
            para que todas las mujeres y hombres con discapacidad se sientan ciudadanos y ciudadanas en igualdad de
            condiciones. No por ser diferente tienen que discriminarte y es importante que tú mismo contribuyas al
            cambio
            social que se necesita para que la sociedad no excluya a las personas que son diferentes.<br /><br />

            El proceso de autodefensa puede ser largo, requiere dedicación y es posible que el resultado final no sea
            exactamente el que esperabas; no obstante, tus esfuerzos siempre serán muy positivos y habrás cambiado
            muchas
            cosas por el camino; por ejemplo, habrás hablado con personas a las que habrás “educado” porque antes ni si
            quiera reparaban en las barreras y los obstáculos que te ponen en una situación de desventaja. Ellos y
            ellas,
            es posible, que también se convertirán en “agentes del cambio” porque tú los habrás instruido.
          </article>

        </div>
      </div>

    </section>
    <section id="documentacion" class="container shadow container-documentacion">
      <p class="title-grand">Documentación</p>
      <ul class="list-item"
        v-if="this.store.documentAutodefense && Object.values(this.store.documentAutodefense).length != 0">
        <li class="main-li"
          v-if="this.store.documentAutodefense[0] && Object.values(this.store.documentAutodefense[0].items).length != 0">
          <input type="checkbox" name="list" id="documentos-autodefensa" />
          <label for="documentos-autodefensa">
            <span>Descarga de documentos - Autodefensa</span>
          </label>
          <div class="inner-container">
        <li v-for="(item, key ) in Object.values(this.store.documentAutodefense[0].items)" :key="key">
          <a :href="item.file.url" target="_blank" rel="noopener noreferrer" title="Descargar archivo"> <i
              class="icon icon-download w-24-px h-24-px vertical-align-middle"></i> {{ item.name }}</a>
        </li>
        </div>
        </li>
        <li class="main-li"
          v-if="this.store.documentAutodefense[1] && Object.values(this.store.documentAutodefense[1].items).length != 0">
          <input type="checkbox" name="list" id="documentos-sentencias" />
          <label for="documentos-sentencias">
            <span>Documentos y sentencias</span>
          </label>
          <div class="inner-container">
        <li v-for="(item, key ) in Object.values(this.store.documentAutodefense[1].items)" :key="key">
          <a :href="item.file.url" target="_blank" rel="noopener noreferrer" title="Descargar archivo"> <i
              class="icon icon-download w-24-px h-24-px vertical-align-middle"></i> {{ item.name }}</a>
        </li>
        </div>
        </li>




        <li class="main-li" v-if="this.store.documentAutodefense[2] && Object.values(this.store.documentAutodefense[2].items).length != 0">
          <input type="checkbox" name="list" id="guia-autodefensa" />
          <label for="guia-autodefensa">
            <span>Guía Autodefensa</span>
          </label>
          <div class="inner-container">
              <li v-for="(item, key ) in Object.values(this.store.documentAutodefense[2].items)" :key="key">
                <a :href="item.file.url" target="_blank" rel="noopener noreferrer" title="Descargar archivo"> 
                  <i class="icon icon-download w-24-px h-24-px vertical-align-middle"></i> 
                  {{ item.name }}
                </a>
              </li>
          </div>
        </li>
      </ul>
    </section>
    <section id="discriminacion" class="container">
      <div class="column-center">
        <h2 class="title-grand title__double-center" data-text="¿Sufre discriminación por discapacidad?">
          Discriminación
        </h2>
      </div>
      <div class="d-flex shadow p-2-rem justify-content-around align-items-center m-top-4-rem  m-bottom-4-rem">
        <h2>Si quiere hacer una pregunta sobre sus derechos o denunciar una discriminación pincha aquí:</h2>
        <router-link to="/contacto" class="btn btn-blue link">Más información</router-link>
      </div>
      <article>
        <p>Seguro que más de una vez se ha encontrado ante situaciones en las que se ha sentido discriminado/a porque
          existían obstáculos que le impedían ejercer sus derechos. Es necesario cambiar algunas cosas de la sociedad
          para
          que todas las mujeres y hombres con discapacidad se sientan ciudadanos y ciudadanas en igualdad de
          condiciones.
          No por ser diferente tienen que discriminarle y es importante que usted contribuya al cambio social que se
          necesita para que la sociedad no excluya a las personas que son diferentes.</p>
        <br />
        <p>El proceso de autodefensa puede ser largo, requiere dedicación y es posible que el resultado final no se
          exactamente el que esperaba; no obstante, sus esfuerzos siempre serán muy positivos y habrá cambiado muchas
          cosas por el camino; por ejemplo, habrá hablado con personas a las que habrá "educado" porque antes ni si
          quiera
          reparaban en las barreras y los obstáculos que nos ponen en una situación de desventaja. Ellos y ellas, es
          posible, que también se convertirán en "agentes del cambio" porque usted los habrá instruido.</p>
        <br />
        <p>Puede acceder a la <a class="text-bold text-primary" href="/defensa-legal#autodefensa">Guía de
            Autodefensa</a> o <a class="text-bold text-primary" href="/defensa-legal#documentacion">descargarse modelos
            genéricos</a>.</p>
      </article>
      <div class="d-flex gap-2-rem m-top-4-rem  m-bottom-4-rem justify-content-center">
        <div class="w-50 p-2-rem shadow text-primary font">
          <a class="text-decoration-none"
            href="https://play.google.com/store/apps/details?id=com.technosite.cermi.denuncias&feature=search_result#?t=W251bGwsMSwyLDEsImNvbS50ZWNobm9zaXRlLmNlcm1pLmRlbnVuY2lhcyJd"
            target="_blank">
            <p class="font-family-frank-ruhl text-bold font-size-21 text-center ">
              Descargue la aplicación de denuncias en su móvil Android
            </p>
            <br />
            <img class="d-block w-12-rem margin-auto" src="/img/google-play-store.png"
              alt="Descargue la aplicación de denuncias en su móvil Android"
              title="Descargue la aplicación de denuncias en su móvil Android">
          </a>
        </div>
        <div class="w-50 p-2-rem shadow text-primary font">
          <a class="text-decoration-none"
            href="https://apps.apple.com/us/app/cermi-denuncia-por-discriminacion/id578593483?l=es&ls=1"
            target="_blank">
            <p class="font-family-frank-ruhl text-bold font-size-21 text-center">Descargue la aplicación de denuncias en
              dispositivos iPhone o iPad</p>
            <br />
            <img class="d-block w-12-rem margin-auto" src="/img/disponible-app-store.png"
              alt="Descargue la aplicación de denuncias en su móvil Android"
              title="Descargue la aplicación de denuncias en su móvil Android">
          </a>
        </div>
      </div>
      <a href="https://www.exteriores.gob.es/" target="_blank" rel="noopener noreferrer">
        <img class="d-block margin-auto w-18-rem m-top-4-rem" alt="Imagen Gobierno de España"
          title="Imagen Gobierno de España" src="/img/gobierno-espana.png">
      </a>

    </section>
    <other-links-interest class="links-interest-box m-y-4-rem container" />
  </section>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import Carrousel2 from "@/components/Global/Carrousel2";
import Ribbon from "@/components/Global/Ribbon";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
import { contentStore } from '@/stores/contents'

export default {
  name: "LegalDefense",
  components: { OtherLinksInterest, Ribbon, Carrousel2, HeroGlobal },

  setup() {
    const store = contentStore();
    store.loadDocAutodefense()
    return {
      store
    }
  },
  watch: {
    "$route.hash"() {
      let element = this.$route.hash.replace('#', '')
      const offsetTop = document.getElementById(element).offsetTop - 150;
      setTimeout(function () {
        scroll({
          behavior: "smooth",
          top: offsetTop,
        });
      }, 200);
    }
  }
}

</script>

<style scoped lang="scss">
@import "../styles/colours";
@import "../styles/icons";

.defense-legal {
  .menu-anclas {
    margin-bottom: 40px;
  }

  .container-grid {
    align-items: flex-start;

    .grid-big {
      width: 60%;
      padding-right: 40px;
    }

    .grid-small {
      width: 40%;
    }

    &.reverse {
      .grid-big {
        padding-left: 40px;
        padding-right: 0px;
      }
    }
  }

  a {
    color: $blue;
  }

  ol {
    list-style: none;
    counter-reset: counter;

    &.first-list {
      counter-set: counter 0;
    }

    &.second-list {
      counter-set: counter 3;
    }

    &.third-list {
      counter-set: counter 5;
    }

    li {
      counter-increment: counter;
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 20px;
    }

    li::before {
      content: counter(counter) " ";
      color: $blueLight;
      font-weight: bold;
      font-size: 50px;
      padding: 0 15px;
      text-align: right;
      font-family: "Frank Ruhl Libre", serif;
      line-height: 45px;
    }
  }

  .box-content {
    background: transparent;

    .list-item.list>li {
      border-bottom: 1px solid $blueLight;
    }

    .inner-container {
      padding-left: 0 !important;

      ul {
        li {
          border-bottom: none;
        }
      }
    }

    li {
      padding: 0 !important;
    }

    label::before,
    .item-content.-one::before {
      bottom: 10px;
    }
  }

  #autodefensa {
    background: #8baddc26;
    padding: 40px 0;
    margin-bottom: 60px;

    h2 {
      color: #8baddc66;
    }
  }

  .list-item .main-li {
    list-style: none;
    border-bottom: 1px solid $blue;
    padding: 15px 0;

    label {
      padding: 0;
      font-size: 22px;

      &:before {
        bottom: -10px;
      }

      span {
        font-size: 20px;
        padding-left: 0 !important;
        color: black;
      }

    }
  }

  .container-documentacion {
    a {
      background: none;
      border-bottom: none;
      color: black;
      font-weight: normal;
      font-size: 18px;
      padding: 0;
      margin-top: 15px;
    }
  }
}

#discriminacion {
  padding: 80px 0;
}
</style>